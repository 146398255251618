import styled from "styled-components"
import _Text from "src/componentsV2/ui/Text"

export const Row = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    text-align: center;

    img {
      margin-top: 30px;
    }

    .col {
      padding: 0;
    }

    .title,
    .desc {
      max-width: 100%;
    }
  }
`

export const Col = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    &.description {
      max-width: 405px;
    }
  }
`

export const Img = styled.img`
  max-width: 600px;
  width: 100%;
  height: auto;
`
